<template>
  <div>
    <template v-if="addContainer">
      <v-layout row>
        <v-spacer></v-spacer>
        <v-select
          dense
          outlined
          :items="languages"
          v-model="currentLanguage"
          :label="$t('Select a language')"
          single-line
          menu-props="auto"
          prepend-inner-icon="mdi-web"
          hide-details
          :color="color"
          :id="id"
          :class="cssProps"
        ></v-select>
      </v-layout>
    </template>

    <template v-else>
      <v-spacer></v-spacer>

      <v-select
        outlined
        dense
        :items="languages"
        v-model="currentLanguage"
        :label="$t('Select a language')"
        single-line
        menu-props="auto"
        prepend-icon="mdi-web"
        hide-details
        :color="color"
        :class="cssProps"
      >
      </v-select>
    </template>
  </div>
</template>

<script>
export default {
  name: "LanguageSelector",
  props: {
    cssProps: {
      typeof: String,
    },
    addContainer: {
      typeof: Boolean,
    },
    whiteColors: {
      typeof: Boolean,
    },
  },
  data() {
    return {
      languages: this.$config.languages,
    };
  },

  computed: {
    currentLanguage: {
      get: function () {
        return this.$i18n.locale();
      },
      set: function (value) {
        this.$i18n.set(value);
      },
    },
    color: function () {
      return this.whiteColors ? "" : "";
    },
    id: function () {
      return this.whiteColors ? "languageSelect" : "";
    },
  },
};
</script>

